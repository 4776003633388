//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  beforeDestroy() {
    this.$el.className = '';
  },

  // mounted() {
  //   window.addEventListener('localeChanged', () => {
  //     this.$i18n.locale = this.$getSelectedLocale();
  //   });
  // },
};
