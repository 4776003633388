//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import singleSpa from 'single-spa';
// import handleErrors from '../../common/handle-api-errors';
import axios from 'axios';

export default {
  name: 'Downloads',

  beforeRouteEnter(to, from, next) {
    next(vm => vm.generateDownloadLinks());
  },

  methods: {
    decodeJwt(token) {
      try {
        const base64Url = token.split('.')[1];
        const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        const jsonPayload = decodeURIComponent(atob(base64).split('').map(c => `%${(`00${c.charCodeAt(0).toString(16)}`).slice(-2)}`).join(''));

        const decodedData = JSON.parse(jsonPayload);
        return decodedData;
      } catch (err) {
        this.$showError(this, err);

        return 0;
      }
    },

    generateDownloadLinks() {
      const d = this.decodeJwt(this.$route.query.t);
      // Default url is in Singapore
      let regionURL = `${"https://connect.8x8.com"}/api/v1`;

      // eslint-disable-next-line no-prototype-builtins
      if (d.hasOwnProperty('regionID') && d.regionID === 1) {
        // If RegionID is Indonesia
        regionURL = `${"https://portal.8x8.id"}/api/v1`;
      // eslint-disable-next-line no-prototype-builtins
      } else if (d.hasOwnProperty('regionID') && d.regionID === 2) {
        // If RegionID is UK
        regionURL = `${"https://portal.8x8.co.uk"}/api/v1`;
      }

      axios({
        method: 'get',
        baseURL: regionURL,
        url: `download?token=${this.$route.query.t}`,
      })
        .then(({ data }) => {
          const { link } = data;
          if (link) {
            // Sanitize url
            const url = new URL(link);

            window.location = url.href;

            this.$message({
              message: this.$t('download.in_progress'),
              type: 'success',
            });
          }

          singleSpa.navigateToUrl('/');
        })
        .catch((err) => {
          // if (window.Bugsnag) {
          //   window.Bugsnag.notify(err);
          // }
          console.warn('Catch :', err); // eslint-disable-line

          this.$showError(this, err);
          singleSpa.navigateToUrl('/');
        });
    },
  },
};
