<template>
  <div
    id="download"
    class="h-full w-full p-10"
  >
    <el-row
      type="flex"
      justify="center"
      align="middle"
    >
      <RouterView />
    </el-row>
  </div>
</template>

<script>
export default {
  beforeDestroy() {
    this.$el.className = '';
  },

  // mounted() {
  //   window.addEventListener('localeChanged', () => {
  //     this.$i18n.locale = this.$getSelectedLocale();
  //   });
  // },
};
</script>

<style scoped>
  #login {
    background-color:#F6F6F6;
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: 100%;
  }
</style>
